import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifyToastComponent } from './components/notify-toast/notify-toast.component';
import { RoutedModalComponent } from './components/routed-modal/routed-modal.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { InnerHtmlLinkDirective } from './directives/inner-html-link.directive';
import { NgLetDirective } from './directives/ng-let.directive';
import { MaterialComponentsModule } from './material-modules.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule, GridModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NoDataComponent } from './components/no-data/no-data.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NgxMaskModule } from 'ngx-mask';
import { KpRegexInputFilterDirective } from './directives/regex-input-filter.directive';
import { ChatParticipationStatePipe } from './pipes/chat-participation-state.pipe';
import { TransformWordByCountPipe } from './pipes/transfor-word-by-count.pipe';
import { KpNoWhiteSpacesDirective } from './directives/no-white-spaces.directive';
import { SvgComponent } from './components/svg/svg.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SelectComponent } from './components/select/select.component';
import { CommentsSwitcherComponent } from './components/comments-switcher/comments-switcher.component';
import { LikesComponent } from './components/likes/likes.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TableComponent } from './components/table/table.component';
import { NgxCutModule } from 'ngx-cut';
import { TransformWordFileSizePipe } from './pipes/transform-word-file.pipe';
import { NgxMasonryModule } from 'ngx-masonry';
import { ChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterDialogComponent } from './dialogs/filter-dialog/filter-dialog.component';
import { IconTextDialogComponent } from './dialogs/icon-text-dialog/icon-text-dialog.component';
import { KpAutozieTextareaDirective } from './directives/autosize-textarea.directive';
import { ContenteditableValueAccessorDirective } from './directives/contenteditable-div-form.directive';
import { FileInlinePreviewComponent } from './components/file-inline-preview/file-inline-preview.component';
import { FileInlineViewComponent } from './components/file-inline-view/file-inline-view.component';
import { SelectDialogComponent } from './dialogs/select-dialog/select-dialog.component';
import { UpcomingEventsComponent } from './components/upcoming-events/upcoming-events.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialogComponent } from './dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { QuillModule } from 'ngx-quill';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FilterDialogControllerComponent } from './components/filter-dialog-controller/filter-dialog-controller.component';
import { MembersListPreviewComponent } from './components/members-list-preview/members-list-preview.component';
import { DescriptionViewComponent } from './components/description-view/description-view.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { MembersListDialogComponent } from './dialogs/members-list-dialog/members-list-dialog.component';
import { MembersListEditComponent } from './components/members-list-edit/members-list-edit.component';
import { ConfirmActionDirective } from './directives/confirm-action.directive';
import { SmartDatePipe } from './pipes/smart-date.pipe';
import { SwitchElementDirective } from './directives/switch-element.directive';
import { StuffItemComponent } from './components/stuff-item/stuff-item';
import { StuffListComponent } from './components/stuff-list/stuff-list';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiBoxComponent } from './components/emoji-box/emoji-box.component';
import { ChatScrollDirective } from './directives/chat-scroll.directive';
import { AutolinkerPipe } from './pipes/autolinker.pipe';
import { AvatarPlaceholderComponent } from './components/avatar-placeholder/avatar-placeholder.component';
import { TermsOfUseComponent } from './components/termsOfUse/terms-of-use.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { YearCalendarComponent } from './components/year-calendar/year-calendar.component';
import { WordEndingDirective } from './directives/words-ending.directive';
import { OnlineStatusPipe } from './pipes/online-status.pipe';
import { EmojiBoxReactionsComponent } from './components/emoji-box-reactions/emoji-box-reactions.component';
import { ParticipantMentionPipe } from './pipes/participant-mentinon.pipe';
import { BlockPlaceholderComponent } from '@shared/components/block-placeholder/block-placeholder.component';
import { transformToLocalTimePipe } from './pipes/transform-to-local-time.pipe';
import { PrepareBackgroundImagePipe } from '@shared/pipes/prepare-background-image.pipe';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { PluralPipe } from '@shared/pipes/plural.pipe';
import { BusinessDatePipe } from './pipes/business-date.pipe';
import { FullUserByIdPipe } from '@shared/pipes/full-user-by-id.pipe';
import { MatRippleModule } from '@angular/material/core';
import { PopupHeaderComponent } from '@shared/components/popup-header/popup-header.component';
import { ProgressBarColorDirective } from './directives/profile-progress-bar.directive';
import { ProfileSidebarMenuComponent } from './components/profile-sidebar-menu/profile-sidebar-menu.component';
import { CodeInputModule } from 'angular-code-input';
import { RedirectToStorePageComponent } from './components/redirect-to-store-page/redirect-to-store-page.component';
import { UserAvatarComponent } from '@shared/components/user-avatar/user-avatar.component';
import { UserPrimaryBusinessPipe } from './pipes/user-primary-business.pipe';
import { SelectDropdownFooterComponent } from '@shared/components/select-dropdown-footer/select-dropdown-footer.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { VisitDateTimePipe } from './pipes/visit-date-time.pipe';
import { DatetimeRelativeTodayPipe } from '@shared/pipes/datetime-relative-today.pipe';
import { HostNamePipe } from '@shared/pipes/host-name.pipe';
import { TimezoneFormatPipe } from '@shared/pipes/timezone-format.pipe';
import { RemoveUnderscorePipe } from '@shared/pipes/remove-underscore.pipe';
import { LastActivityIndicatorComponent } from '@shared/components/last-activity-indicator/last-activity-indicator.component';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialComponentsModule,
  MatRippleModule,
  HttpClientModule,
  FlexLayoutModule,
  GridModule,
  LayoutModule,
  DpDatePickerModule,
  NgxCutModule,
  NgxMasonryModule,
  ChartsModule,
  InfiniteScrollModule,
  ImageCropperModule,
  PickerModule,
  NgSelectModule,
  CodeInputModule,
];

const components = [
  NoDataComponent,
  PaginatorComponent,
  LoaderComponent,
  DatePickerComponent,
  SvgComponent,
  SelectComponent,
  CommentsSwitcherComponent,
  LikesComponent,
  PageTitleComponent,
  TableComponent,
  FilterDialogComponent,
  IconTextDialogComponent,
  SelectDialogComponent,
  FileInlinePreviewComponent,
  FileInlineViewComponent,
  UpcomingEventsComponent,
  ImageCropperDialogComponent,
  FilterDialogControllerComponent,
  DescriptionViewComponent,
  MembersListPreviewComponent,
  RadioButtonComponent,
  MembersListDialogComponent,
  MembersListEditComponent,
  StuffListComponent,
  StuffItemComponent,
  EmojiBoxComponent,
  AvatarPlaceholderComponent,
  TermsOfUseComponent,
  YearCalendarComponent,
  EmojiBoxReactionsComponent,
  BlockPlaceholderComponent,
  MenuComponent,
  UserAvatarComponent,

  PopupHeaderComponent,
  ProfileSidebarMenuComponent,
  RedirectToStorePageComponent,
  SelectDropdownFooterComponent,

  SwitcherComponent,
  NotifyToastComponent,
  UserCardComponent,
  RoutedModalComponent,
  TextInputComponent,
  LastActivityIndicatorComponent,
];

const directives = [
  KpRegexInputFilterDirective,
  KpNoWhiteSpacesDirective,
  KpAutozieTextareaDirective,
  ContenteditableValueAccessorDirective,
  TooltipDirective,
  ConfirmActionDirective,
  SwitchElementDirective,
  ChatScrollDirective,
  WordEndingDirective,
  ProgressBarColorDirective,
  InnerHtmlLinkDirective,
  NgLetDirective,
];

const pipes = [
  TransformWordByCountPipe,
  TransformWordFileSizePipe,
  SafeUrlPipe,
  SafeHtmlPipe,
  SmartDatePipe,
  AutolinkerPipe,
  OnlineStatusPipe,
  ParticipantMentionPipe,
  transformToLocalTimePipe,
  PrepareBackgroundImagePipe,
  BusinessDatePipe,
  PluralPipe,
  FullUserByIdPipe,
  UserPrimaryBusinessPipe,
  FormControlPipe,
  VisitDateTimePipe,
  DatetimeRelativeTodayPipe,
  HostNamePipe,
  ChatParticipationStatePipe,
  TimezoneFormatPipe,
  RemoveUnderscorePipe,
  TruncateStringPipe
];

@NgModule({
  declarations: [components, directives, pipes],
  imports: [modules, NgxMaskModule.forRoot(), QuillModule.forRoot()],
  exports: [modules, components, directives, pipes, NgxMaskModule, QuillModule],
  providers: [TitleCasePipe, ...pipes],
})
export class SharedModule {}
